import React from "react";
import { graphql, Link } from "gatsby";
// import Gitalk from "gatsby-plugin-gitalk";

import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import PortableText from "../components/portableText";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj, toPlainText } from "../lib/helpers";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const PageTemplate = (props) => {
  const { location, data, errors } = props;
  const page = data && data.page;

  const heroBg = () => {
    if (page.mainImage) {
      return {
        backgroundImage: `url(${imageUrlFor(buildImageObj(page.mainImage))
          .auto("format")
          .fit("max")
          .url()})`,
      };
    }
    return {};
  };

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {page && (
        <SEO
          title={page.title || "Untitled"}
          description={page.description}
          image={page.mainImage}
          pathname={page.slug.current}
          location={location}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <div className="main">
        <div className="heroBanner-innerPage about-banner" style={heroBg()}>
          <section className="banner-caption  d-flex">
            <div className="container d-flex align-content-center flex-wrap">
              <div className="banner-caption_inner  ">
                <h1>{page.title} </h1>
                <p className="paragraph-large breadcrumb ">
                  <Link to="/">Home</Link>{" "}
                  <span className="icon-arrow_forward"></span> {page.title}
                </p>
              </div>
            </div>
          </section>
        </div>
        <section className=" blog-post-details pt-5 pb-5">
          <div className="container">
            {page._rawBody && <PortableText blocks={page._rawBody} />}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PageTemplate;
